import apiSlice from '@base/redux/apiSlice';

const adminAudits = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getStats: builder.query<InternalStats, null>({
            query: () => ({
                url: 'admin/stats',
                method: 'GET',
            }),
        }),
        getUsersSnapshot: builder.query<UserSnapshots, void>({
            query: () => ({
                url: 'admin/snapshots/users',
                method: 'GET',
            }),
        }),
    }),
});

export default adminAudits;
export const { useGetStatsQuery, useGetUsersSnapshotQuery } = adminAudits;
