import { createListenerMiddleware } from '@reduxjs/toolkit';
import { logout } from '@base/redux/reducers/authSlice';
import apiSlice from '@base/redux/apiSlice';
import { persistor } from '@base/redux/store';

const authMiddleware = createListenerMiddleware();

authMiddleware.startListening({
    actionCreator: logout,
    effect: async (_action, api) => {
        api.cancelActiveListeners();
        apiSlice.util.resetApiState();
        await persistor.purge();
        await persistor.persist();
    },
});

export default authMiddleware;
