import apiSlice from '@base/redux/apiSlice';

const adminUserSubscription = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRecentUserSubscriptionsAdmin: builder.query<UserSubscription[], null>({
            query: () => ({
                url: 'admin/user-subscriptions?limit=15&sort=-created&filter[active]=true&include=user',
                method: 'GET',
            }),
            transformResponse: (response: { data: UserSubscription[] } & Pagination) => response.data,
        }),
        getUserSubscriptionsAdmin: builder.mutation<
            { data: UserSubscription[] } & Pagination,
            { options?: QueryFilterOptions }
        >({
            query: ({ options }) => ({
                url: `admin/user-subscriptions${options}`,
                method: 'GET',
            }),
        }),
    }),
});

export default adminUserSubscription;
export const { useGetRecentUserSubscriptionsAdminQuery, useGetUserSubscriptionsAdminMutation } = adminUserSubscription;
