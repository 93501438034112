import { useEffect, useState } from 'react';
import { useEcho } from '@base/contexts/EchoContext';
import { useGetAllOrganizationProjectsMutation, useGetOrganizationMutation } from '@base/redux/features/organization';
import useWorkspaceRoute from '@base/hooks/useWorkspaceRoute';
import { useGetProjectMutation } from '@base/redux/features/project';
import apiSlice from '@base/redux/apiSlice';
import { useAppDispatch } from '@base/redux/hooks';
import WorkspaceContext from '@base/contexts/WorkspaceContext';
import { Channel } from 'laravel-echo';

const WorkspaceProvider = ({ children }: WorkspaceProps) => {
    const { echo, privateChannel } = useEcho();
    const dispatch = useAppDispatch();
    const { workspaceId, workspaceProjectId } = useWorkspaceRoute();
    const [organization, setOrganization] = useState<Organization | undefined>(undefined);
    const [projects, setProjects] = useState<Project[] | undefined>(undefined);
    const [organizationSocket, setOrganizationSocket] = useState<Channel | null>(null);
    const [organizationErrorMsg, setOrganizationErrorMsg] = useState<string | undefined>(undefined);
    const [project, setProject] = useState<Project | undefined>(undefined);
    const [projectSocket, setProjectSocket] = useState<Channel | null>(null);
    const [projectErrorMsg, setProjectErrorMsg] = useState<string | undefined>(undefined);
    const [getOrganization, { data: organizationData, error: organizationError, reset: organizationReset }] =
        useGetOrganizationMutation();
    const [getProjects, { data: orgProjectsData, error: orgProjectsError, reset: resetOrgProjects }] =
        useGetAllOrganizationProjectsMutation();
    const [getProject, { data: projectData, error: projectError, reset: projectReset }] = useGetProjectMutation();
    const isViewingProject = workspaceProjectId !== undefined && organization !== undefined;

    const refetchOrganization = () => workspaceId && getOrganization({ orgId: workspaceId });
    const refetchProjects = () => workspaceId && organization && getProjects({ orgId: workspaceId });
    const refetchProject = () => workspaceProjectId && getProject({ projectId: workspaceProjectId });

    const isOrganizationPending =
        !organization ||
        !organization.member ||
        !organization.member.organizationMember ||
        organization.member.organizationMember.is_pending_approval ||
        organization.member.organizationMember.is_pending_acceptance;

    useEffect(() => {
        setOrganizationErrorMsg(undefined);

        if (!workspaceId) {
            setOrganization(undefined);
            setProjects(undefined);
            organizationReset();
            resetOrgProjects();
            return;
        }

        if (organization && organization.id === workspaceId) {
            return;
        }

        if (organization && organization.id !== workspaceId) {
            setOrganization(undefined);
            setProjects(undefined);
            organizationReset();
            resetOrgProjects();
            return;
        }

        refetchOrganization();
    }, [workspaceId, organization]);

    useEffect(() => {
        organization?.id && refetchProjects();
    }, [organization?.id]);

    useEffect(() => {
        organizationData && setOrganization(organizationData);
    }, [organizationData]);

    useEffect(() => {
        orgProjectsData && setProjects(orgProjectsData);
    }, [orgProjectsData]);

    useEffect(() => {
        if (organizationError) {
            const response = organizationError as NotFoundError;
            setOrganizationErrorMsg(response.data.message);
        }
    }, [organizationError]);

    useEffect(() => {
        orgProjectsError && setProjects([]);
    }, [orgProjectsError]);

    useEffect(() => {
        if (!echo || !organization || !workspaceId) {
            return;
        }

        if (!organizationSocket) {
            setOrganizationSocket(echo.private(`intract.organization.${organization.id}`));
            return;
        }

        const refetchMembersToMention = () => {
            dispatch(apiSlice.util.invalidateTags(['project-members-to-mention']));
        };

        organizationSocket
            .listen('.organization.updated', refetchOrganization)
            .listen('.organization.archived', refetchOrganization)
            .listen('.organization.subscription.updated', refetchOrganization)
            .listen('.organization.member.added', refetchMembersToMention)
            .listen('.organization.member.updated', refetchMembersToMention)
            .listen('.organization.member.removed', refetchMembersToMention)
            .listen('.project.created', refetchProjects)
            .listen('.project.updated', refetchProjects)
            .listen('.project.archived', refetchProjects);

        return () => {
            if (echo && organizationSocket) {
                organizationSocket
                    .stopListening('.organization.updated', refetchOrganization)
                    .stopListening('.organization.archived', refetchOrganization)
                    .stopListening('.organization.subscription.updated', refetchOrganization)
                    .stopListening('.organization.member.added', refetchMembersToMention)
                    .stopListening('.organization.member.updated', refetchMembersToMention)
                    .stopListening('.organization.member.removed', refetchMembersToMention)
                    .stopListening('.project.created', refetchProjects)
                    .stopListening('.project.updated', refetchProjects)
                    .stopListening('.project.archived', refetchProjects);
                echo.leave(`intract.organization.${organization.id}`);
                setOrganizationSocket(null);
            }
        };
    }, [echo, organizationSocket, organization?.id, workspaceId]);

    useEffect(() => {
        if (!privateChannel || !organization || !workspaceId) {
            return;
        }

        const refetch = (data: { organization: Organization }) => {
            refetchProject();

            if (data.organization.id !== organization.id) {
                return;
            }

            refetchOrganization();
            refetchProjects();
            dispatch(apiSlice.util.invalidateTags(['project-members-to-mention']));
        };

        const onNotification = (notification: NotificationModel) => {
            if (notification.organization_id === organization.id) {
                refetchOrganization();
            }
        };

        privateChannel
            .listen('.notification', onNotification)
            .listen('.organization.member.added', refetch)
            .listen('.organization.member.updated', refetch)
            .listen('.organization.member.removed', refetch);

        return () => {
            if (privateChannel) {
                privateChannel
                    .stopListening('.notification', onNotification)
                    .stopListening('.organization.member.added', refetch)
                    .stopListening('.organization.member.updated', refetch)
                    .stopListening('.organization.member.removed', refetch);
            }
        };
    }, [privateChannel, organization?.id, workspaceId, workspaceProjectId]);

    useEffect(() => {
        setProjectErrorMsg(undefined);

        if (!isViewingProject) {
            setProject(undefined);
            projectReset();
            return;
        }

        if (project && project.id === workspaceProjectId) {
            return;
        }

        if (project && project.id !== workspaceProjectId) {
            setProject(undefined);
            projectReset();
            return;
        }

        refetchProject();
    }, [project, workspaceProjectId, isViewingProject]);

    useEffect(() => {
        if (!projectData) {
            return;
        }

        if (!project && !projectErrorMsg && projectData.organization_id !== organization?.id) {
            setProjectErrorMsg('Invalid project for organization.');
        }

        setProject(projectData);
    }, [projectData, organization?.id, project]);

    useEffect(() => {
        if (projectError) {
            const response = projectError as NotFoundError;
            setProjectErrorMsg(response.data.message);
        }
    }, [projectError]);

    useEffect(() => {
        if (!echo || !project || !isViewingProject) {
            return;
        }

        if (!projectSocket) {
            setProjectSocket(echo.private(`intract.project.${project.id}`));
            return;
        }

        const refetchMembersToMention = () => {
            dispatch(apiSlice.util.invalidateTags(['project-members-to-mention']));
        };

        projectSocket
            .listen('.project.updated', refetchProject)
            .listen('.project.archived', refetchProject)
            .listen('.project.member.added', refetchMembersToMention)
            .listen('.project.member.updated', refetchMembersToMention)
            .listen('.project.member.removed', refetchMembersToMention);

        return () => {
            if (echo && projectSocket) {
                projectSocket
                    .stopListening('.project.updated', refetchProject)
                    .stopListening('.project.archived', refetchProject)
                    .stopListening('.project.member.added', refetchMembersToMention)
                    .stopListening('.project.member.updated', refetchMembersToMention)
                    .stopListening('.project.member.removed', refetchMembersToMention);
                echo.leave(`intract.project.${project.id}`);
                setProjectSocket(null);
            }
        };
    }, [echo, projectSocket, project?.id, isViewingProject]);

    useEffect(() => {
        if (!privateChannel || !organization) {
            return;
        }

        const refetch = (data: { project: Project }) => {
            if (data.project.organization_id !== organization.id) {
                return;
            }

            if (project) {
                refetchProject();
            }

            refetchOrganization();
            refetchProjects();
            dispatch(apiSlice.util.invalidateTags(['project-members-to-mention']));
        };

        privateChannel
            .listen('.project.member.added', refetch)
            .listen('.project.member.updated', refetch)
            .listen('.project.member.removed', refetch);

        return () => {
            if (privateChannel) {
                privateChannel
                    .stopListening('.project.member.added', refetch)
                    .stopListening('.project.member.updated', refetch)
                    .stopListening('.project.member.removed', refetch);
            }
        };
    }, [privateChannel, organization, project?.id, isViewingProject]);

    return (
        <WorkspaceContext
            value={{
                organization,
                projects,
                isViewingOrg: workspaceId !== undefined,
                isOrganizationPending,
                workspaceId,
                organizationSocket,
                refetchOrganization,
                refetchProjects,
                organizationErrorMsg,
                project,
                isViewingProject,
                workspaceProjectId,
                projectSocket,
                refetchProject,
                projectErrorMsg,
            }}
        >
            {children}
        </WorkspaceContext>
    );
};

export default WorkspaceProvider;
