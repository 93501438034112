import apiSlice from '@base/redux/apiSlice';

const project = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProject: builder.mutation<Project, GetProjectRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}?load=organization,memberAppend`,
                method: 'GET',
            }),
        }),
        storeProject: builder.mutation<Project, StoreOrganizationProjectRequest>({
            query: ({ orgId, name }) => ({
                url: `developer/organizations/${orgId}/projects`,
                method: 'POST',
                body: { name },
            }),
        }),
        updateProject: builder.mutation<Project, UpdateProjectRequest>({
            query: ({ projectId, name, is_published, joinable }) => ({
                url: `developer/projects/${projectId}`,
                method: 'PUT',
                body: { name, is_published, joinable },
            }),
        }),
        archiveProject: builder.mutation<null, ArchiveProjectRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}`,
                method: 'DELETE',
            }),
        }),
        restoreProject: builder.mutation<Project, RestoreProjectRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/restore`,
                method: 'PUT',
            }),
        }),
        purgeProject: builder.mutation<null, ArchiveProjectRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/purge`,
                method: 'DELETE',
            }),
        }),
        updateProjectAvatar: builder.mutation<UpdateProjectAvatarResponse, UpdateProjectAvatarRequest>({
            query: ({ projectId, avatar }) => ({
                url: `developer/projects/${projectId}/avatar`,
                method: 'POST',
                body: avatar,
            }),
        }),
        getSharedProject: builder.query<Project, GetSharedProjectRequest>({
            query: ({ code }) => ({
                url: `shared/projects/${code}`,
            }),
        }),
    }),
});

export default project;
export const {
    useGetProjectMutation,
    useStoreProjectMutation,
    useUpdateProjectMutation,
    useUpdateProjectAvatarMutation,
    useArchiveProjectMutation,
    useRestoreProjectMutation,
    usePurgeProjectMutation,
    useGetSharedProjectQuery,
} = project;
