import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import adminAudits from '@base/redux/features/adminStats';

const initialState: AdminState = {
    recentActiveUsers: [],
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        updateRecentActiveUsers: (state, action: PayloadAction<number>) => {
            state.recentActiveUsers = [...state.recentActiveUsers, action.payload];
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(adminAudits.endpoints.getStats.matchFulfilled, (state, action) => {
            state.recentActiveUsers = [...state.recentActiveUsers, action.payload.recent_active_users];
        });
    },
    selectors: {
        getRecentActiveUsers: (state) => state.recentActiveUsers,
    },
});

export const { updateRecentActiveUsers } = adminSlice.actions;
export const { getRecentActiveUsers } = adminSlice.selectors;
export default adminSlice;
